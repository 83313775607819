/** @jsxImportSource @emotion/react */
import Link from 'next/link'
import { GetServerSideProps } from 'next'
import { Twitter } from 'grommet-icons'
import { useTranslation } from 'next-i18next'
import { Box, Button, Heading } from 'grommet'

import { LatestPublicGroup, TopPublicGroup } from 'db-tables'
import useDeviceSize from 'hooks/useDevice'
import Layout from 'components/layout/Layout'
import { routes, runtimeFlags } from 'routes'
import { supabaseClient } from 'supabaseClient'
import { tableHoverStyle } from 'util/ui-tables'
import GroupList from 'components/group/listing/GroupList'
import { CommonPageProps, createPage } from 'util/ssp'
import MoodlightLogo from 'components/icon/MoodlightLogo'

const PUBLIC_GROUP_CODE = 'try-it-live'

type PageProps = CommonPageProps & {
  publicGroups?: TopPublicGroup[]
  latestGroups?: LatestPublicGroup[]
}
export const getServerSideProps: GetServerSideProps<PageProps> = async function (ctx) {
  return await createPage<PageProps>(ctx, {
    loadData: async (props) => {
      const { data: publicGroups } = await supabaseClient
        .from<TopPublicGroup>('top_public_groups')
        .select('*')
        .limit(10)
      const { data: latestGroups } = await supabaseClient
        .from<LatestPublicGroup>('latest_public_groups')
        .select('*')
        .limit(10)
      return { props: { ...props, publicGroups, latestGroups } }
    },
  })
}

export const HomePage = ({ publicGroups, latestGroups }: PageProps) => {
  const { t } = useTranslation()
  const { small } = useDeviceSize()
  return (
    <Layout>
      <Box align="center" margin={{ bottom: 'large' }}>
        <Box margin="large">
          <Box direction="row" justify="center">
            <MoodlightLogo css={{ height: '5rem' }} />
          </Box>
        </Box>

        <Box direction={small ? 'column' : 'row'} gap="small">
          <Link
            href={routes.group.view(PUBLIC_GROUP_CODE, { [runtimeFlags.liveForest]: '' })}
            passHref>
            <Button primary label={t('pages.index.share-your-mood')} />
          </Link>
          <Button
            href="https://twitter.com/_moodlight_"
            target="_blank"
            label={t('pages.index.spread-the-word')}
            icon={<Twitter color="#4267B2" />}
            reverse
          />
        </Box>

        <Box
          round
          pad="small"
          margin="large"
          width="large"
          justify="center"
          background={{ color: small ? 'white' : undefined, opacity: 'strong' }}
          css={tableHoverStyle}>
          <Heading level={3} textAlign="center" fill>
            {t('pages.group.list-public-top')}
          </Heading>
          <GroupList groups={publicGroups ?? []} hideScope showTotal />
        </Box>
        <Box
          round
          pad="small"
          margin={{ bottom: 'large' }}
          width="large"
          background={{ color: small ? 'white' : undefined, opacity: 'strong' }}
          css={tableHoverStyle}>
          <Heading textAlign="center" level={3} fill>
            {t('pages.group.list-public-latest')}
          </Heading>
          <GroupList groups={latestGroups ?? []} hideScope />
        </Box>
      </Box>
    </Layout>
  )
}

export default HomePage
